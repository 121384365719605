import { Component } from 'react'

class Login extends Component {
  componentDidMount () {
    // If we are using a remote API, redirect back here after login
    let url = `${process.env.REACT_APP_LOGIN_URL}user/login?destination=${window.location.origin}`
    window.location.href = url
  }
  render () {
    return false
  }
}

export default Login
