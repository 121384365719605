import React from 'react'
import { ReferenceField, Edit, SimpleForm, Create, TextField, TextInput } from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const MachineNoteTitle = ({ record }) => {
  return <span>{record && record.title ? record.title : 'Machine Note'}</span>
}

export const MachineNoteEdit = (props) => {
  return (
    <Edit title={<MachineNoteTitle />} undoable={false} {...props}>
      <SimpleForm redirect={'/machine'}>
        <ReferenceField label="Machine" source="machine" reference="machine" linkType={false} >
          <TextField source="name" />
        </ReferenceField>
        <RichTextInput label="Note" source="note" />
        <TextInput label="Archived On" source="deletedAt" />
      </SimpleForm>
    </Edit>
  )
}

export const MachineNoteCreate = (props) => {
  const { state = { } } = props.location
  return (
    <Create title={'Create Machine Note'} {...props} >
      <SimpleForm defaultValue={ state } redirect={'/machine/' + state.machine + '/show/1'}>
        <ReferenceField label="Machine" source="machine" reference="machine" record={state} linkType={false} >
          <TextField source="name" />
        </ReferenceField>
        <RichTextInput label="Note" source="note" />
      </SimpleForm>
    </Create>
  )
}

export default {
  MachineNoteEdit,
  MachineNoteCreate,
}
