import React from 'react'
import { renderName, selectProps } from '../util'
import { Create, Edit, SimpleForm, ReferenceInput, SelectInput, FunctionField, SelectArrayInput,
  ReferenceField, TextField, required } from 'react-admin'

export const GroupMembershipCreate = (props) => {
  // Get the default values from the location state, or an empty object if undefined
  const { state = { } } = props.location
  // Get the user and group from the default values, or null if undefined
  const { user, group } = state
  // Figure out where to redirect on submit
  let redirect
  if (user) {
    redirect = `/user/${user._id}/show`
  }
  else if (group) {
    redirect = `/group/${group._id}/show`
  }
  else {
    redirect = '/'
  }
  return (
    <Create title={'Create Customer Membership'} {...props}>
      <SimpleForm defaultValue={ state } redirect={redirect}>
        {
          user
            ? <ReferenceField label="User" source="user" reference="user" record={state} linkType={false}>
              <FunctionField render={renderName()} />
            </ReferenceField>
            : <ReferenceInput label="User" source="user" reference="user" validate={required()} {...selectProps}>
              <SelectInput optionText={<FunctionField render={renderName()} />} optionValue="_id" />
            </ReferenceInput>
        }
        {
          group
            ? <ReferenceField label="Customer" source="group" reference="group" record={state} linkType={false}>
              <TextField source="title" />
            </ReferenceField>
            : <ReferenceInput label="Customer" source="group" reference="group" validate={required()} {...selectProps}>
              <SelectInput optionText="title" optionValue="_id" />
            </ReferenceInput>
        }
        <SelectArrayInput source="roles" choices={[
          { id: 'group_admin', name: 'Administrator' },
          { id: 'therapist', name: 'Therapist' },
        ]} />
      </SimpleForm>
    </Create>
  )
}

export const GroupMembershipEdit = (props) => (
  <Edit title={'Edit Group Membership'} undoable={false} {...props}>
    <SimpleForm redirect='/group'>
      <ReferenceField label="Customer" source="group" reference="group">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField label="User" source="user" reference="user">
        <FunctionField render={renderName()} />
      </ReferenceField>
      <SelectArrayInput source="roles" choices={[
        { id: 'group_admin', name: 'Administrator' },
        { id: 'therapist', name: 'Therapist' },
      ]} />
    </SimpleForm>
  </Edit>
)
