import React from 'react'
import { renderName, renderRoles, paginationProps, selectProps, $type } from '../util'
import { Link } from 'react-router-dom'
import { List, Datagrid, TextField, SimpleForm, SelectInput,
  EditButton, DeleteButton, Show, TabbedShowLayout, Tab,
  TextInput, NumberInput, Edit, Create, Filter, ReferenceField, ReferenceManyField, FunctionField,
  BooleanInput, BooleanField, DateField, DateInput, ReferenceInput, CreateButton, required } from 'react-admin'

const validateRequired = [required()]
const GroupTitle = ({ record }) => {
  return <span>{record && record.title ? record.title : 'Customer'}</span>
}

export const GroupList = (props) => (
  <List title="Customers" {...props} bulkActionButtons={false} {...paginationProps} filters={ <GroupFilter /> } filterDefaultValues={{ deletedAt: { $type: $type.null } }}>
    <Datagrid rowClick="show">
      <TextField label="Title" source="title" />
      <TextField label="Free Spins per Month" source="numFreeSpins" />
      <TextField label="Cost per Spin" source="costPerSpin" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

export const GroupShow = (props) => (
  <Show title={<GroupTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField label="Title" source="title" />
        <TextField label="Free Spins per Month" source="numFreeSpins" />
        <TextField label="Cost per Spin" source="costPerSpin" />
        <BooleanField label="Collect Waivers" source="isRiderWaiverRequired" />
        <DateField label="Accepted No-waiver Terms On" source="acceptWaiverTermsAt" options={{ year: 'numeric', month: 'long', day: 'numeric' }}/>
        <ReferenceField label="No-waiver Terms Accepted By" source="acceptWaiverTermsBy" reference="user">
          <FunctionField render={renderName()} />
        </ReferenceField>
      </Tab>
      <Tab label="Members">
        <CreateButton
          label="Add Member"
          component={Link}
          to={{
            pathname: '/groupMembership/create',
            state: { group: props.id },
          }}
        />
        <ReferenceManyField addLabel={false} reference="groupMembership" target="group">
          <Datagrid>
            <ReferenceField label="Name" source="user" reference="user">
              <FunctionField render={renderName()} />
            </ReferenceField>
            <FunctionField source="roles" label="Member Role" render={renderRoles()} />
            <EditButton/>
            <DeleteButton basePath={'/group/' + props.id + '/show/1'} undoable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>

)

export const GroupEdit = (props) => (
  <Edit title={'Customer Edit'} undoable={false} {...props}>
    <SimpleForm>
      <TextInput label="Name" source="title" validate={validateRequired} />
      <NumberInput label="Free Spins per Month" source="numFreeSpins" />
      <NumberInput label="Cost per Spin" source="costPerSpin" />
      <BooleanInput label="Collect Waivers" source="isRiderWaiverRequired" />
      <DateInput label="Accepted No-waiver Terms On" source="acceptWaiverTermsAt" options={{ clearable: true, format: 'MMMM dd, yyyy' }} />
      <ReferenceInput label="No-waiver Terms Accepted By" source="acceptWaiverTermsBy" reference="user" {...selectProps}>
        <SelectInput optionText={<FunctionField render={renderName()} />} optionValue="_id" />
      </ReferenceInput>
      <TextInput label="Archived On" source="deletedAt" />
    </SimpleForm>
  </Edit>
)

export const GroupCreate = (props) => (
  <Create title={'Create Customer'} {...props}>
    <SimpleForm>
      <TextInput label="Name" source="title" validate={validateRequired} />
    </SimpleForm>
  </Create>
)

export const GroupFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name Search" source="title[$regex]" alwaysOn />
    <SelectInput label="Status" source="deletedAt[$type]" choices={[
      { id: $type.null, name: 'Active' },
      { id: $type.date, name: 'Archived' },
    ]} alwaysOn />
  </Filter>
)
