import React, { Fragment } from 'react'
import { BulkDeleteButton, CardActions, ExportButton } from 'react-admin'
import Typography from '@material-ui/core/Typography'

export const ConfirmBulkDelete = props => (
  <Fragment>
    <BulkDeleteButton {...props} undoable={false} />
  </Fragment>
)

export const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions && React.cloneElement(bulkActions, {
      basePath,
      filterValues,
      resource,
      selectedIds,
      onUnselectItems,
    })}
    {filters && React.cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    }) }
    <ExportButton
      label="Export All"
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </CardActions>
)

export const ReferenceManyTotal = props => (
  <Typography variant="body1">{props.total}</Typography>
)
