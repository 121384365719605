import React from 'react'
import { paginationProps, $type } from '../util'
import { List, Show, Datagrid, TextField, DateField,
  Edit, SimpleForm, RichTextField, BooleanInput, SimpleShowLayout,
  TextInput, Create, Filter, EditButton, DeleteButton, SelectInput, BooleanField } from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const LegalTitle = ({ record }) => {
  return <span>{record && record.title ? record.title : 'Legal'}</span>
}

export const LegalList = (props) => {
  return (
    <List title="Legal List" {...props} filters={ <LegalFilter/> } {...paginationProps} filterDefaultValues={{ deletedAt: { $type: $type.null } }}>
      <Datagrid rowClick="show">
        <BooleanField label="Current" source="isCurrent" />
        <TextField label="Title" source="title" />
        <DateField label="Created At" source="createdAt" options={{ year: 'numeric', month: 'long', day: 'numeric' }}/>
        <EditButton />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  )
}

export const LegalShow = props => (
  <Show title={<LegalTitle />} {...props}>
    <SimpleShowLayout>
      <BooleanField label="Current" source="isCurrent" />
      <TextField label="Title" source="title" />
      <RichTextField source="body" />
      <DateField label="Created At" source="createdAt" options={{ year: 'numeric', month: 'long', day: 'numeric' }}/>
    </SimpleShowLayout>
  </Show>
)

export const LegalEdit = (props) => {
  return (
    <Edit title={'Edit Legal'} undoable={false} {...props}>
      <SimpleForm>
        <BooleanInput label="Current" source="isCurrent" />
        <TextInput label="Title" source="title" />
        <RichTextInput source="body" />
        <TextInput label="Archived On" source="deletedAt" />
      </SimpleForm>
    </Edit>
  )
}

export const LegalCreate = (props) => {
  return (
    <Create title={'Create Legal'} {...props}>
      <SimpleForm>
        <BooleanInput label="Current" source="isCurrent" />
        <TextInput label="Title" source="title" />
        <RichTextInput source="body" />
      </SimpleForm>
    </Create>
  )
}

export const LegalFilter = (props) => (
  <Filter {...props}>
    <SelectInput label="Status" source="deletedAt[$type]" choices={[
      { id: $type.null, name: 'Active' },
      { id: $type.date, name: 'Archived' },
    ]} alwaysOn required/>
  </Filter>
)

export default {
  LegalList,
  LegalEdit,
  LegalCreate,
  LegalFilter,
}
