import React, { Component } from 'react'
import { Admin, Resource } from 'react-admin'
import { restClient } from 'ra-data-feathers'
import Login from './Login'
import { MachineList, MachineShow, MachineEdit, MachineCreate } from './Machine'
import { MachineNoteEdit, MachineNoteCreate } from './MachineNote'
import { PatientList, PatientShow, PatientEdit, PatientCreate } from './Patient'
import { UserList, UserShow, UserEdit, UserCreate } from './User'
import { GroupList, GroupShow, GroupEdit, GroupCreate } from './Group'
import { GroupMembershipCreate, GroupMembershipEdit } from './GroupMembership'
import { LocationList, LocationShow, LocationEdit, LocationCreate } from './Location'
import { AnnouncementList, AnnouncementShow, AnnouncementEdit, AnnouncementCreate } from './Announcement'
import { LegalList, LegalShow, LegalEdit, LegalCreate } from './Legal'
import NotAuthorized from './NotAuthorized'

import PatientIcon from '@material-ui/icons/Face'
import CustomerIcon from '@material-ui/icons/Business'
import LocationIcon from '@material-ui/icons/Room'
import MachineIcon from '@material-ui/icons/EventSeat'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import UserIcon from '@material-ui/icons/Group'

import app from 'common/client/app'
import authProvider from '../authProvider'

import { createMuiTheme } from '@material-ui/core/styles'
const { addParams } = require('../util')

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#77ba3e',
    },
    secondary: {
      main: 'rgb(25, 50, 74)'
    },
  },
  typography: {
    fontFamily: 'Cairo',
  },
})

class App extends Component {
  render () {
    app.hooks({ before: addParams })
    return (
      // use ListGuesser and EditGuesser to get input lists
      <Admin theme={theme} loginPage={Login} authProvider={authProvider} dataProvider={restClient(app, { id: '_id', usePatch: true })}>
        {
          permissions => {
            if (permissions) {
              return [
                <Resource name="patient" icon={PatientIcon} list={PatientList} show={PatientShow} edit={PatientEdit} create={PatientCreate} />,
                <Resource name="group" icon={CustomerIcon} list={GroupList} show={GroupShow} edit={GroupEdit} create={GroupCreate} options={{ label: 'Customers' }} />,
                <Resource name="groupMembership" create={GroupMembershipCreate} edit={GroupMembershipEdit} />,
                <Resource name="location" icon={LocationIcon} list={LocationList} show={LocationShow} edit={LocationEdit} create={LocationCreate}/>,
                <Resource name="machine" icon={MachineIcon} list={MachineList} show={MachineShow} edit={MachineEdit} create={MachineCreate} />,
                <Resource name="machineNote" create={MachineNoteCreate} edit={MachineNoteEdit} />,
                <Resource name="user" icon={UserIcon} list={UserList} show={UserShow} edit={UserEdit} create={UserCreate} />,
                <Resource name="announcement" icon={AnnouncementIcon} list={AnnouncementList} show={AnnouncementShow} edit={AnnouncementEdit} create={AnnouncementCreate} />,
                <Resource name="legal" list={LegalList} show={LegalShow} edit={LegalEdit} create={LegalCreate} options={{ label: 'Legal' }}/>,
                <Resource name="ride" />,
                <Resource name="userLegalLog" />,
                <Resource name="machineConfig" />,
              ]
            }
            else {
              return [ <NotAuthorized /> ]
            }
          }
        }
      </Admin>
    )
  }
}

export default App
