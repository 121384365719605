import React from 'react'
import { renderAddress, paginationProps, selectProps, $type } from '../util'
import { List, Show, Datagrid, ReferenceField, TextField, NumberField,
  Edit, SimpleForm, TextInput, Create, ReferenceInput, SimpleShowLayout,
  NumberInput, SelectInput, DisabledInput, Filter, FunctionField, DeleteButton,
  EditButton, required, minLength, maxLength, number } from 'react-admin'

const validateName = [required()]
// may not want these, just showing what is possible
const validatePhoneNumber = [ number(), minLength(10), maxLength(10) ]
const validateZipCode = [number(), minLength(5), maxLength(5)]

const states = require('../lib/states.json')
const LocationTitle = ({ record }) => {
  return <span>{record && record.title ? record.title : 'Location'}</span>
}

export const LocationList = (props) => {
  return (
    <List title="Locations" {...props} bulkActionButtons={false} filters={ <LocationFilter /> } {...paginationProps} filterDefaultValues={{ deletedAt: { $type: $type.null } }}>
      <Datagrid rowClick="show">
        <TextField label="Title" source="title" />
        <NumberField label="Free Spins" source="numFreeSpins" />
        <NumberField label="Cost Per Spin" source="costPerSpin" />
        <ReferenceField label="Customer" source="group" reference="group">
          <TextField source="title" />
        </ReferenceField>
        <EditButton />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  )
}

export const LocationShow = props => (
  <Show title={<LocationTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <FunctionField style={{ whiteSpace: 'pre-wrap' }} label="Address" render={renderAddress()} />
      <NumberField label="Phone" source="phone" />
      <NumberField label="Free Spins" source="numFreeSpins" />
      <NumberField label="Cost Per Spin" source="costPerSpin" />
      <TextField label="Scheduling URL" source="schedulingUrl" />
      <ReferenceField label="Customer" source="group" reference="group">
        <TextField source="title" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)

export const LocationEdit = (props) => {
  return (
    <Edit title={'Edit Location'} undoable={false} {...props}>
      <SimpleForm>
        <TextInput label="Title" source="title" validate={validateName} />
        <TextInput label="Street" source="address.thoroughfare" />
        <TextInput label="Apartment" source="address.premise" />
        <TextInput label="City" source="address.locality" />
        <SelectInput label="State" source="address.administrative_area" choices={states} />
        <TextInput label="Zip" source="address.postal_code" validate={validateZipCode} />
        <DisabledInput label="Country" source="address.country" defaultValue="US"/>
        <TextInput label="Phone" source="phone" validate={validatePhoneNumber} />
        <NumberInput label="Number of Free Spins" source="numFreeSpins" />
        <NumberInput label="Cost Per Spin" source="costPerSpin" />
        <TextInput label="Scheduling URL" source="schedulingUrl" />
        <TextInput label="Archived On" source="deletedAt" />
        <ReferenceInput label="Customer" source="group" reference="group" {...selectProps}>
          <SelectInput optionText="title" optionValue="_id" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}

export const LocationCreate = (props) => {
  return (
    <Create title={'Create Location'} {...props}>
      <SimpleForm>
        <TextInput label="Name" source="title" validate={validateName} />
        <TextInput label="Street" source="address.thoroughfare" />
        <TextInput label="Apartment" source="address.premise" />
        <TextInput label="City" source="address.locality" />
        <SelectInput label="State" source="address.administrative_area" choices={states} />
        <DisabledInput label="Country" source="address.country" defaultValue="US"/>
        <TextInput label="Phone" source="phone" validate={validatePhoneNumber} />
        <NumberInput label="Number of Free Spins" source="numFreeSpins" />
        <NumberInput label="Cost Per Spin" source="costPerSpin" />
        <TextInput label="Scheduling URL" source="schedulingUrl" />
        <ReferenceInput label="Customer" source="group" reference="group" {...selectProps}>
          <SelectInput optionText="title" optionValue="_id" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export const LocationFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Customer" source="group" reference="group" alwaysOn {...selectProps}>
      <SelectInput optionText="title" optionValue="_id" />
    </ReferenceInput>
    <SelectInput label="Status" source="deletedAt[$type]" choices={[
      { id: $type.null, name: 'Active' },
      { id: $type.date, name: 'Archived' },
    ]} alwaysOn required/>
  </Filter>
)

export default {
  LocationList,
  LocationEdit,
  LocationCreate,
  LocationFilter
}
