import React from 'react'
import { paginationProps, selectProps, renderName, renderExcerpt, renderDuration, $type } from '../util'
import { PostActions, ReferenceManyTotal } from '../util/components'
import { Link } from 'react-router-dom'
import { List, Show, Datagrid, ReferenceField, TextField, BooleanField, Edit,
  ReferenceInput, SimpleForm, TextInput, Filter, Create, TabbedShowLayout, Tab,
  SelectInput, EditButton, DeleteButton, ReferenceManyField,
  RichTextField, CreateButton, NumberField, FunctionField, DateField, SingleFieldList } from 'react-admin'

const MachineTitle = ({ record }) => {
  return <span>{record && record.name ? record.name : 'Machine'}</span>
}

export const MachineList = (props) => {
  return (
    <List title="Machines" {...props} bulkActionButtons={false} filters={<MachineFilter />} {...paginationProps} filterDefaultValues={{ deletedAt: { $type: $type.null } }}>
      <Datagrid rowClick="show">
        <TextField source="name" label="Name" />
        <BooleanField source="connected" label="Connected" />
        <TextField source="mode" label="Mode" />
        <ReferenceField label="Customer" source="group" reference="group" linkType="show">
          <TextField source="title" />
        </ReferenceField>
        <TextField label="Device ID" source="deviceId" />
        <TextField source="currentAngle" label="Current Angle" />
        <EditButton />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  )
}

export const MachineShow = props => (
  <Show title={<MachineTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="name" label="Name" />
        <BooleanField source="connected" label="Connected" />
        <TextField source="deviceId" label="Device ID" />
        <TextField source="serial" label="Serial Number" />
        <TextField label="Particle URL" source="particleUrl" />
        <ReferenceField label="Customer" source="group" reference="group">
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField label="Location" source="location" reference="location">
          <TextField source="title" />
        </ReferenceField>
        <ReferenceManyField label='Paired User' reference="machineConfig" target="machine">
          <SingleFieldList>
            <ReferenceField source="user" reference="user">
              <FunctionField render={renderName()} />
            </ReferenceField>
          </SingleFieldList>
        </ReferenceManyField>
        <TextField source="currentAngle" label="Current Angle" />
        <TextField source="numSpins" label="Total Spins" />
        <FunctionField label="Motor Duration" render={renderDuration()} />
        { /* TODO change total rides to the report service once that is built */ }
        <ReferenceManyField label="Total Rides" reference="ride" target="machine" {...selectProps} >
          <ReferenceManyTotal />
        </ReferenceManyField>
      </Tab>
      <Tab label="Notes">
        <CreateButton
          label="Add Note"
          component={Link}
          to={{
            pathname: '/machineNote/create',
            state: { machine: props.id },
          }}
        />
        <ReferenceManyField addLabel={false} reference="machineNote" target="machine">
          <List title=" Machine Notes" {...props} actions={<PostActions />} filters={<MachineNoteFilter />} bulkActionButtons={false} createButton={false} filter={{ machine: props.id }} filterDefaultValues={{ deletedAt: { $type: $type.null } }}>
            <Datagrid rowClick="edit" expand={<RichTextField source="note" />}>
              <FunctionField render={renderExcerpt()} />
              <ReferenceField label="Created By" source="createdBy" reference="user">
                <FunctionField render={renderName()} />
              </ReferenceField>
              <DateField label="Created At" source="createdAt" options={{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
              <NumberField label="Spins" source="numSpins" />
              <DeleteButton basePath={'/machine/' + props.id + '/show/1'} undoable={false} />
            </Datagrid>
          </List>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const MachineEdit = (props) => (
  <Edit undoable={false} title={'Edit Machine'} {...props}>
    <SimpleForm>
      <TextInput source="name" label="Name"/>
      <TextInput source="deviceId" label="Device ID" />
      <TextInput source="serial" label="Serial Number" />
      <TextInput label="Particle URL" source="particleUrl" />
      <ReferenceInput label="Customer" source="group" reference="group" {...selectProps}>
        <SelectInput optionText="title" optionValue="_id" />
      </ReferenceInput>
      <ReferenceInput label="Location" source="location" reference="location" {...selectProps}>
        <SelectInput optionText="title" optionValue="id" />
      </ReferenceInput>
      <TextInput label="Archived On" source="deletedAt" />
    </SimpleForm>
  </Edit>
)

export const MachineCreate = (props) => (
  <Create title="Create Machine" {...props}>
    <SimpleForm>
      <TextInput source="name" label="Name" />
      <TextInput source="deviceId" label="Device ID" />
      <TextInput source="serial" label="Serial Number" />
      <TextInput label="Particle URL" source="particleUrl" />
      <ReferenceInput label="Customer" source="group" reference="group" {...selectProps}>
        <SelectInput optionText="title" optionValue="_id" />
      </ReferenceInput>
      <ReferenceInput label="Location" source="location" reference="location" {...selectProps}>
        <SelectInput optionText="title" optionValue="id" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const MachineFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Customer" source="group" reference="group" alwaysOn {...selectProps}>
      <SelectInput optionText="title" optionValue="_id" />
    </ReferenceInput>
    <TextInput label="Device ID" source="deviceId" alwaysOn />
    <SelectInput label="Status" source="deletedAt[$type]" choices={[
      { id: $type.null, name: 'Active' },
      { id: $type.date, name: 'Archived' },
    ]} alwaysOn required/>
  </Filter>
)

export const MachineNoteFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="User" source="createdBy" reference="user" alwaysOn {...selectProps}>
      <SelectInput optionText={<FunctionField render={renderName()} />} optionValue="_id" />
    </ReferenceInput>
    <SelectInput label="Status" source="deletedAt[$type]" choices={[
      { id: $type.null, name: 'Active' },
      { id: $type.date, name: 'Archived' },
    ]} alwaysOn required/>
  </Filter>
)

export default {
  MachineList,
  MachineEdit,
  MachineCreate,
  MachineFilter,
  MachineNoteFilter,
}
